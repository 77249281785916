<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Картка ПЗ</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка ПЗ</div>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="8" lg="6" class="overflow-hidden text-wrap">
            <div class="mt-6">
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">ID ПЗ:</span>
                <span class="font-weight-medium">{{ firmware.id }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Тип пристрою:</span>
                <v-select
                  v-if="editCard"
                  v-model="editedItem.device_type"
                  :items="machinesTypesDictionary"
                  item-value="value"
                  item-text="description"
                  @input="$v.editedItem.device_type.$touch()"
                  @blur="$v.editedItem.device_type.$touch()"
                  :error-messages="inputErrors('device_type')"
                  dense
                />
                <span v-else class="font-weight-medium">{{ getMachineDesc(firmware.device_type) }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Модель плати автомата:</span>
                <v-select
                  v-if="editCard"
                  v-model="editedItem.terminal_model"
                  :items="terminalsModelsDictionary"
                  item-value="name"
                  item-text="description"
                  @input="$v.editedItem.terminal_model.$touch()"
                  @blur="$v.editedItem.terminal_model.$touch()"
                  :error-messages="inputErrors('terminal_model')"
                  dense
                />
                <span v-else class="font-weight-medium">{{ firmware.terminal_model }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Версія ПЗ:</span>
                <v-text-field
                  v-if="editCard"
                  v-model="editedItem.version_name"
                  @input="$v.editedItem.version_name.$touch()"
                  @blur="$v.editedItem.version_name.$touch()"
                  :error-messages="inputErrors('version_name')"
                  dense
                />
                <span v-else class="font-weight-medium">{{ firmware.version_name }}</span>
              </div>
              <div v-if="firmware.creator" class="d-flex align-baseline custom-field">
                <span class="mr-1">Хто створив:</span>
                <span class="font-weight-medium">{{ firmware.creator.first_name }} {{ firmware.creator.last_name }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Дата та час створення:</span>
                <span class="font-weight-medium"
                  >{{ firmware.created_at | getShortDate }} {{ firmware.created_at | getShortTime }}</span
                >
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Дата та час змін:</span>
                <span class="font-weight-medium"
                  >{{ firmware.updated_at | getShortDate }} {{ firmware.updated_at | getShortTime }}</span
                >
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Опис для користувача:</span>
                <v-text-field v-if="editCard" v-model="editedItem.description" />
                <span v-else class="font-weight-medium">{{ firmware.description }}</span>
              </div>
              <div v-if="firmware.available_to_update" class="d-flex align-baseline custom-field">
                <span class="mr-1">На яку версію ПЗ термінала ставити:</span>
                <PagingSelect
                  v-if="editCard"
                  :value="editedItem.available_to_update"
                  :options="firmwaresForSearch"
                  :options-params="firmwareVersionsOptions"
                  item-value="id"
                  item-text="version_name"
                  multiple
                  @change="(value) => (editedItem.available_to_update = value)"
                >
                  <template v-slot:item="{ item }">{{ item.version_name }} (#{{ item.id }})</template>
                </PagingSelect>
                <span v-else class="font-weight-medium">{{
                  firmware.available_to_update.map((item) => `${item.version_name} (#${item.id})`).join(', ')
                }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">На яку версію ПЗ плати автомат ставити:</span>
                <v-select
                  v-if="editCard"
                  v-model="editedItem.supported_devices"
                  :items="machinesTypesDictionary"
                  item-value="name"
                  item-text="description"
                  @input="$v.editedItem.supported_devices.$touch()"
                  @blur="$v.editedItem.supported_devices.$touch()"
                  :error-messages="inputErrors('supported_devices')"
                  multiple
                  dense
                />
                <span v-else class="font-weight-medium">{{
                  firmware.supported_devices ? firmware.supported_devices.join(', ') : ''
                }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Розмір файлу, byte:</span>
                <span class="font-weight-medium">{{ firmware.file_size }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Кількість чанків:</span>
                <span class="font-weight-medium">{{ firmware.chunk_count }}</span>
              </div>
              <div v-if="!editCard" class="d-flex align-baseline custom-field">
                <span class="mr-1">Файл ПЗ:</span>
                <span class="font-weight-medium">{{ firmware.file }}</span>
              </div>
              <div class="d-flex align-baseline custom-field">
                <span class="mr-1">Доступна всім:</span>
                <v-select
                  v-if="editCard"
                  v-model="editedItem.is_available"
                  :items="[
                    { value: true, text: 'Так' },
                    { value: false, text: 'Ні' },
                  ]"
                  item-value="value"
                  item-text="text"
                  dense
                />
                <span v-else class="font-weight-medium">{{ firmware.is_available ? 'Так' : 'Ні' }}</span>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="
              servicePointsShortInfo &&
              servicePointsShortInfo.length &&
              servicePointsShortInfo.filter((item) => item.terminals).length &&
              !installMode &&
              !editCard
            "
            cols="12"
            md="4"
          >
            <div class="mt-6">
              <span class="font-weight-medium">Встановлено на терміналах:</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Торгівельна точка</th>
                      <th>Термінали</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sp in servicePointsShortInfo.filter((item) => item.terminals)" :key="sp.id">
                      <td>
                        <router-link
                          :to="{ name: 'ServicePointCard', params: { servicePointId: sp.id } }"
                          class="text-decoration-none"
                        >
                          {{ sp.name }}
                          {{ sp.address ? `${sp.address.city}, ${sp.address.street}, ${sp.address.building_number}` : '' }}
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          v-for="t in sp.terminals"
                          :key="t.id"
                          :to="{ name: 'TerminalCard', params: { terminalId: t.id } }"
                          class="text-decoration-none"
                        >
                          T{{ t.id }}
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
          <v-col v-if="editCard" cols="4">
            <span>Завантажити файл ПЗ:</span>
            <FileUploader
              :firmwareId="currentFirmwareId"
              accept="*"
              actionType="uploadFirmwareFile"
              :label="editedItem.file"
              prepend-icon="mdi-chip"
            />
          </v-col>
          <v-col cols="4">
            <div v-if="installMode && !progressItems.length">
              <span>Термінали, куди ставимо:</span>
              <PagingSelect
                :options="terminals"
                :options-meta="terminalsMeta"
                :options-params="terminalsOptions"
                item-value="id"
                label="Оберіть термінали для встановлення ПЗ"
                @change="(values) => (selectedTerminals = values)"
                multiple
              >
                <template v-slot:item="{ item }">
                  <span v-if="item.firmware">T{{ item.id }} ({{ item.firmware.version_name }})</span>
                  <span v-else>T{{ item.id }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="mx-1">T{{ item.id }}</span>
                </template>
              </PagingSelect>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="progressItems.length" fluid>
        <v-divider></v-divider>
        <div class="mt-4">
          <p>Встановлення на терміналах:</p>
          <p
            v-for="item in progressItems"
            :key="item.id"
            class="d-flex flex-row justify-space-between align-center"
            style="width: 350px"
          >
            <router-link
              :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.payload.terminal.id } }"
              class="text-decoration-none"
            >
              <b>T{{ item.payload.terminal.id }}</b>
            </router-link>
            {{ item.statusMessage }}
            <v-progress-linear
              v-if="item.status !== 2"
              :color="item.status === 3 ? 'red' : 'light-green'"
              height="10"
              :value="item.percents"
              striped
              rounded
              style="width: 100px"
            />
            ({{ item.percents }}%)
            <v-tooltip top>
              <template v-slot:activator="{ on, attr }">
                <v-icon v-bind="attr" v-on="on" @click="toggleAlert(item)">mdi-eye-outline</v-icon>
              </template>
              {{ item.visible ? 'Приховати алерт' : 'Відобразити алерт' }}
            </v-tooltip>
          </p>
        </div>
      </v-container>
      <v-container v-if="installMode" fluid>
        <div class="d-flex justify-end">
          <v-btn class="mr-2" small :disabled="!selectedTerminals.length || !!progressItems.length" @click="installFirmware"
            >Встановити</v-btn
          >
        </div>
      </v-container>
      <v-container v-else fluid>
        <div class="d-flex justify-end">
          <v-btn :disabled="!!progressItems.length" @click="edit()" class="mr-2" small>
            {{ !editCard ? 'Редагувати' : 'Повернутись' }}
          </v-btn>
          <v-btn v-show="editCard" @click="showModal = true" color="primary" small>Зберегти</v-btn>
        </div>
      </v-container>
    </v-card>

    <v-card class="mt-5"><Logs :preselected-event-types="[10, 15]" /> </v-card>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="save" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { FIRMWARE_SCHEMA } from '@/const/apiSchemas'
import { mapActions, mapState } from 'vuex'
import ConvertDevicesTypes from '@/mixins/convertDevicesTypes'
import ConvertDate from '@/mixins/convertDate'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import CloneDeep from '@/mixins/cloneDeep'
import BackButton from '@/components/common/BackButton.vue'
import cloneDeep from '@/mixins/cloneDeep'
import Logs from '@/components/common/Logs.vue'

export default {
  name: 'FirmwareCard',
  components: {
    Logs,
    BackButton,
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    PagingSelect: () => import('@/components/common/PagingSelect'),
    FileUploader: () => import('@/components/common/FileUploader'),
  },

  mixins: [validationMixin, CloneDeep, ConvertDevicesTypes, ConvertDate, cloneDeep],

  data() {
    return {
      isLoaded: false,
      editCard: false,
      showModal: false,
      firmwareVersionsLoading: false,
      editedItem: null,
      selectedTerminals: [],
      terminalsOptions: null,
      firmwareVersionsOptions: null,
      rules: [(value) => !value || value.size < 5000000 || 'Розмір файлу не повинен перевищувати 5 Мб!'],
    }
  },

  validations() {
    return {
      editedItem: {
        device_type: { required },
        terminal_model: { required },
        version_name: { required },
        supported_devices: { required },
      },
    }
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('terminals', ['terminals', 'terminalsMeta']),
    ...mapState('firmwares', ['firmware', 'firmwaresForSearch', 'installingFirmwares']),
    ...mapState('logs', ['progressData']),
    ...mapState('dictionaries', ['machinesTypesDictionary', 'terminalsModelsDictionary']),
    ...mapState('servicePoints', ['servicePointsShortInfo']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentFirmwareId() {
      return this.$route.params.firmwareId
    },
    installMode() {
      return this.$route.query.installMode
    },
    progressItems() {
      return this.progressData.filter((item) => item.payload.firmware.id === this.firmware.id)
    },
  },

  methods: {
    ...mapActions('terminals', ['loadTerminals']),
    ...mapActions('firmwares', ['loadSelectedFirmware', 'loadFirmwares', 'updateSelectedFirmware', 'upgradeFirmware']),
    ...mapActions('logs', ['updateProgressItem']),
    ...mapActions('servicePoints', ['getPointsShortInfo']),

    async initialize() {
      this.editedItem = this.cloneObjectDeep(FIRMWARE_SCHEMA)
      this.firmwareVersionsOptions = {
        loadingFunction: this.loadFirmwares,
        payload: {
          company: this.currentCompanyId,
          forSearch: 1,
        },
      }
      this.terminalsOptions = {
        loadingFunction: this.loadTerminals,
        payload: {
          companyId: +this.currentCompanyId,
          sort: {
            id: 'desc',
          },
          forSearch: 0,
        },
      }
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          firmwareId: this.currentFirmwareId,
        }
        await this.loadSelectedFirmware(payload)
        this.editedItem = this.cloneObjectDeep(this.firmware)
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
      await this.loadFirmwareVersions()
      await this.getPointsShortInfo({
        company: this.currentCompanyId,
        'terminals.firmware.id': this.firmware.id,
      })
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.editedItem[fieldName].$dirty) return errors
      !this.$v.editedItem[fieldName].required && errors.push('Це поле обов"язкове')
      return errors
    },

    async loadFirmwareVersions() {
      this.firmwareVersionsLoading = true
      const payload = { company: this.currentCompanyId }
      await this.loadFirmwares(payload)
      this.firmwareVersionsLoading = false
    },

    edit() {
      this.editCard = !this.editCard
      if (this.editCard) {
        this.editedItem.available_to_update = [
          ...this.editedItem.available_to_update.map(({ id, device_type, version_name }) => ({ id, device_type, version_name })),
        ]
      }
    },

    async save() {
      const payload = {
        companyId: this.currentCompanyId,
        firmwareId: this.firmware.id,
        updatedFirmware: { ...this.editedItem },
      }
      if (payload.updatedFirmware.id) {
        delete payload.updatedFirmware.id
      }
      await this.updateSelectedFirmware(payload)
      this.showModal = false
      this.editCard = false
    },

    async installFirmware() {
      const payload = {
        companyId: this.currentCompanyId,
        firmware: this.editedItem,
        terminals: this.selectedTerminals,
      }
      await this.upgradeFirmware(payload)
    },

    async toggleAlert(progressItem) {
      await this.updateProgressItem({
        ...progressItem,
        visible: !progressItem.visible,
      })
    },
  },
}
</script>
